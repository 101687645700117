<template>
  <!-- 

    TODO: Not Found

    https://www.etusersgroup.org/wp-content/uploads/etug-restricted/2017meeting/013%20-%20Primary%20Explosives%20Testing%20with%20the%20ABL%20Friction%20Machine%20-%20Jason%20Ford.pdf

 -->
  <div
    class="meeting px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full pb-30">
        <TitleComponent
          class="py-8"
          title="October 2017 Meeting"
          size="large"
        />
        <div
          class="flex flex-wrap mt-8 mb-4 px-6 py-10"
          style="background-color:#f7f5f4;"
        >
          <div class="w-full md:w-1/2 pr-3">
            <h2
              class="mb-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              ATTENDEES
            </h2>
            <p class="font-bold mb-4">
              Below is a list of some of the entities represented at the
              meeting:
            </p>
            <ul class="list-disc p-light ml-10">
              <li>Aerospace Corporation</li>
              <li>BAE Systems</li>
              <li>
                BATF / National Center for Explosives Training &amp; Research
              </li>
              <li>Canadian Explosives Research Laboratory</li>
              <li>Department of Homeland Security</li>
              <li>Department of Justice</li>
              <li>Esterline Defense Technologies</li>
              <li>Energetic Materials Research and Testing Center</li>
              <li>Federal Bureau of Investigation (FBI)</li>
              <li>
                Federal Institute for Materials Research and Testing (BAM)
              </li>
              <li>Lawrence Livermore National Laboratory</li>
              <li>Los Alamos National Laboratory</li>
              <li>Naval Research Laboratory</li>
              <li>Naval Surface Warfare Center</li>
              <li>
                Orbital ATK
                <ul class="list-disc p-light ml-8">
                  <li>ABL</li>
                  <li>Corporate</li>
                  <li>Bacchus</li>
                  <li>Promontory</li>
                  <li>Lake City</li>
                </ul>
              </li>
              <li>Safety Management Services, Inc.</li>
              <li>Tooele Army Depot</li>
              <li>
                Sporting Arms and Ammunition Manufacturers’ Institute (DG
                Advisor, LLC)
              </li>
              <li>Sandia National Laboratory</li>
              <li>Signature Science, LLC</li>
              <li>U.S. Army RDECOM – ARDEC – Picatinny Arsenal</li>
              <li>Vista Outdoor – CCI/Speer</li>
              <li>Vista Outdoor – Federal Premium Ammunition</li>
            </ul>
            <img src="@/assets/meeting/2017-min.jpg" />
          </div>
          <div class="w-full md:w-1/2 pl-3">
            <div class="mb-6">
              <h2
                class="mb-6"
                style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
              >
                MEETING PROCEEDINGS
              </h2>
              <div
                style="border-color:#e7e4e2;border-width:0px;background-color:#ffffff;"
                class="px-6 py-4 mb-4 fusion-panel panel-default fusion-toggle-no-divider fusion-toggle-boxed-mode"
                role="tabpanel"
              >
                <div class="panel-heading pl-6">
                  <h4
                    class="panel-title toggle"
                    style="position:relative;"
                    data-fontsize="16"
                    data-lineheight="22"
                  >
                    <a
                      @click="showContent = showContent === 1 ? false : 1"
                      :class="{ open: showContent === 1 }"
                      style="cursor:pointer;"
                      aria-expanded="false"
                      aria-selected="false"
                      aria-controls="811950d795ab93cb4"
                      role="tab"
                      data-toggle="collapse"
                    >
                      <span
                        class="fusion-toggle-icon-wrapper"
                        aria-hidden="true"
                      >
                        <i class="fa-fusion-box"></i>
                      </span>
                      <span
                        class="fusion-toggle-heading font-bold"
                        style="color:#ef9a3d;"
                        >2017 Meeting Proceedings</span
                      >
                    </a>
                  </h4>
                </div>
                <transition name="fade">
                  <div
                    id="811950d795ab93cb4"
                    class="panel-collapse collapse"
                    v-if="showContent === 1"
                  >
                    <div
                      class="flex flex-col panel-body toggle-content fusion-clearfix pt-6"
                    >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2017%2F001%20-%20Welcome%202017.pdf?alt=media&token=d0e3d07f-b08a-4df2-88cf-42edb7e182e9"
                        >001 – Opening Remarks – Bob Ford</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2017%2F002%20-%20Review%20of%20ETUG%20Charter%20and%20Standards.pdf?alt=media&token=8782aaa8-0d5a-401f-9f5c-e6d42879c2a6"
                        >002 – Review of ETUG Charter and Standards – Bob
                        Ford</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2017%2F003%20-%20BAM%20Friction%20Round%20Robin%20Intro%20-%20Clint%20Guymon.pdf?alt=media&token=c8adc74d-6f86-4911-880a-37de31227672"
                        >003 – BAM Friction Round Robin Intro – Clint Guymon</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2017%2F004%20-%20SMS%20BAM%20Friction%20Results%20-%20Clint%20Guymon.pdf?alt=media&token=2a7ea9b5-5105-4e92-b973-62aa5ee8c7ab"
                        >004 – SMS BAM Friction Round Robin Results – Clint
                        Guymon</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2017%2F007%20-%20BAM%20Friction%20Round%20Robin%20Results%20-%20Hongzhao%20Tian.pdf?alt=media&token=2599f06c-0206-4ec7-a278-dd9e516f7613"
                        >007 – BAM Friction Round Robin Results – Hongzhao
                        Tian</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2017%2F008%20-%20BAM%20Friction%20ETUG%20Presentation%20(Bauman).pdf?alt=media&token=d758a92f-694b-4a24-83dc-00d3a6af5998"
                        >008 – BAM Friction Round Robin Results – Bauman</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2017%2F009%20-%20BAM%20Friction%20Round%20Robin%20Summary%20-%20Clint%20Guymon.pdf?alt=media&token=394f2a8a-883b-40b9-80d7-7bc9918ace1e"
                        >009 – BAM Friction Round Robin Summary – Clint
                        Guymon</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2017%2F010%20-%20ETUG%20Certification%20Discussion%20-%20Jared%20Teter.pdf?alt=media&token=b7e69f41-4e31-4d0c-992f-f34089efb6fc"
                        >010 – ETUG Certification Discussion – Jared Teter</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2017%2F011%20International%20Shipping%20Containers%20-%20Ben%20Barrett.pdf?alt=media&token=662977e8-5d58-40cd-84f7-b23fc31ca7c2"
                        >011 – International Shipping Containers – Ben
                        Barrett</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2017%2F012%20-%20Pellet%20Preparation%20-%20Patrick%20Braun.pdf?alt=media&token=c52eb2bb-4aaf-417a-8904-4c5b15975c77"
                        >012 – Pellet Preparation – Patrick Braun</a
                      >
                      <a
                        class="mb-2 link"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2017%2F013%20-%20Primary%20Explosives%20Testing%20with%20the%20ABL%20Friction%20Machine%20%20-%20Jason%20Ford.pdf?alt=media&token=2b95c1d7-5a95-4961-8ee7-0efd56fb4b34"
                        >013 – Primary Explosives Testing with the ABL Friction
                        Machine – Jason Ford</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2017%2F014%20-%20Shot%20Differentiation%20of%20ABL%20ESD%20Measurements%20of%20MTV%20Ignition%20Composition-Matthew%20Porter.pdf?alt=media&token=7257d0ee-5235-453d-bbcb-1c363e108290"
                        >014 – Shot Differentiation of ABL ESD Measurements of
                        MTV Ignition Composition – Matthew Porter</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2017%2F015%20-%20SEFi%20ETUG%20Presentation_Final%20Ostrow.pdf?alt=media&token=cfab2696-fb1e-4e6b-a718-fe060ef6b124"
                        >015 – Standard Explosive Films for Small-Scale Safety
                        Testing – Benjamin Ostrow</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2017%2F016%20-%20BAM%20Friction%20Porcelain%20Surface%20Finish%20Effect%20PETN%20Results%20-%20Jason%20Ford.pdf?alt=media&token=198ff056-c5e6-4d40-9cae-33c68a8b531d"
                        >016 – BAM Friction Porcelain Surface Finish Effect PETN
                        Results – Jason Ford</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2017%2F017%20-%20ETUG%20Hot%20Spot%2010.5.17%20(10)%20-%20John%20Reynolds.pdf?alt=media&token=8cce5b88-16e4-4672-bc8d-2a294c728a1b"
                        >017 – Hot Spot Formation in Drop Hammer Testing of HMX
                        – John Reynolds</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2017%2F018%20-%20ETUG%20DHT_Presentation-Robert%20Paoletti.pdf?alt=media&token=b4480355-4066-43ca-ad4a-884aa34929de"
                        >018 – Drop Hammer Momentum and Energy Transfer Study –
                        Robert Paoletti and Ariel Boston</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2017%2F021%20-%20In-Process%20Testing%20%26%20Risk%20Analysis%20-%20Jared%20Teter.pdf?alt=media&token=cbcce195-b87e-49bb-a1ce-525135be6817"
                        >021 – In-Process Testing &amp; Risk Analysis – Jared
                        Teter</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2017%2F022%20-%20ETUG%20Meeting%20Slides-Shannon%20Thompson.pdf?alt=media&token=c96de1dd-647d-411e-8220-a6f346e1655b"
                        >022 – Sensitivity Data and Manufacturing Application –
                        Shannon Thompson</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2017%2F023%20-%20Presentation%20ARDEC%20Gap%20Test%20Wrobel%20Et%20Users%20Group%20Oct%202017.pdf?alt=media&token=beeea56d-d3a3-486e-ac17-d47a199bac5c"
                        >023 – In-Process Classification of Propellant using the
                        UN Gap Test – Erik Wrobel</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2017%2F024%20-%20In-process%20Hazard%20Management%20via%20CRT-Alexander%20von%20Oertzen.pdf?alt=media&token=b6d7feb9-74d0-455f-846d-612517d69dea"
                        >024 – In-process Hazard Management via Codified
                        Regulatory Tools (Germany) – Alexander von Oertzen</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2017%2F025%20-%20ETUG%20Meeting%20Thermal%202017%2010.7.17%20(8a)%20-%20John%20Reynolds.pdf?alt=media&token=642bab9c-f94d-424b-bed6-b35b78c2398f"
                        >025 – Safety Assessments of Thermally Damaged Energetic
                        Materials – John Reynolds</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2017%2F026%20-%20History%20of%20Thermal%20Measurements%20and%20Theory%20ETUG%20Presentation-Boyd%20Hill.pdf?alt=media&token=7ec4e16f-8348-43aa-8532-38b099381ac9"
                        >026 – History of Thermal Measurements and Theory – Boyd
                        Hill</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2017%2F028%20-%20Lyon%20Improved%20System%20version%20September%202017.pdf?alt=media&token=87dd72d4-24a2-4b7b-ac37-702015cd63b1"
                        >028 – An Improved System for Safeguard Categorization
                        and Qualitative Analysis – Larry Lyon</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2017%2F029%20-%20GHS%202.1%20Revision%20Status%20Update%20-%20Bob%20Ford.pdf?alt=media&token=f3aab083-1678-4763-8e94-e3e28a68c793"
                        >029 – GHS 2.1 Revision Status Update – Bob Ford</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2017%2F029b%20-%20CERL%20O.2%20O.3%20Round-Robin.pdf?alt=media&token=a10d80a4-3a19-4b0e-91f3-343f4b0b6cfe"
                        >029b – O.2 and O.3 Test Optimization – Richard Bowes
                        and Sandra Goldthorp</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2017%2F030%20-%20An%20Evaluation%20of%20Shock%20Testing%20Used%20in%20Hazard%20Classification-Paul%20Braithwaite.pdf?alt=media&token=cd9a8c3e-18e6-4fe5-ad14-db8a9a0b3adf"
                        >030 – An Evaluation of Shock Testing Used in Hazard
                        Classification – Paul Braithwaite</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2017%2F031%20-%20Shock%20Pressure%20Measurement%20-%20Troy%20Gardner.pdf?alt=media&token=e03b9a91-628e-4dc4-9f03-64efaae0afde"
                        >031 – Shock Pressure Measurement – Troy Gardner</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2017%2F032%20-%20Propane%20Fueled%20Fast%20Cook-off%20Burner%20Design%20Summary%20-%20Kirt%20Sasser.pdf?alt=media&token=826a0c44-d84c-4462-bef2-d5b39596cac6"
                        >032 – Propane Fueled Fast Cook-off Burner Design
                        Summary – Kirt Sasser</a
                      >
                    </div>
                  </div>
                </transition>
              </div>
              <p class="p-light">
                These files are for participants of the ET Users Group. As such
                please consider the information as ‘approved for internal use
                only. Feel free to distribute this information within your
                company or group as needed. If individuals or organizations
                outside your company or group desire access to this information,
                please
                <router-link class="link" to="/contact-us"
                  >Contact Us</router-link
                >.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";

export default {
  components: {
    TitleComponent
  },
  data: function() {
    return {
      showContent: false
    };
  }
};
</script>

<style lang="scss">
.meeting {
  .fusion-button.button-1 {
    border-radius: 0px;
  }

  .panel-title a .fa-fusion-box:before {
    position: absolute;
    left: -2rem;
    top: 2px;
    content: url("../../assets/plus-solid.svg");
    filter: invert(70%) sepia(90%) saturate(601%) hue-rotate(326deg)
      brightness(94%) contrast(100%);
  }

  .panel-title a.open .fa-fusion-box:before {
    content: url("../../assets/minus-solid.svg");
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .btn:hover {
    background-color: #464d5f !important;
  }
}
</style>
